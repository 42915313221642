<template>
  <r-page page-title="Refund">
    <template #page-header>
      <h2 class="page-title">Data Refund</h2>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <h4 class="invoice-table-title">Invoice History</h4>
        </div>
        <div class="level-right">
          <div class="filter-active status" v-if="statusArray.length !== 0">
            Status +{{ statusArray.length }}
            <span>
              <b-icon
                class="click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearStatus()"
              />
            </span>
          </div>
          <b-button
            icon-left="filter-outline"
            label="Filter"
            type="is-light"
            class="mx-2"
            @click.native="openFilter()"
          ></b-button>
          <GeneralFilter
            :isFilterOpen="isFilterOpen"
            :statusList="statuses"
            @closeFilter="closeFilter"
            @filterBy="filterBy($event)"
            @reset="resetState()"
            @loadData="loadInvoiceRefundData()"
          ></GeneralFilter>
          <b-input
            placeholder="Search"
            icon-right="magnify"
            type="is-light"
            icon-right-clickable
            @icon-right-click="searchIconClick(search)"
            @keydown.native.enter="searchIconClick(search)"
            :load="isRefundLoading"
          ></b-input>
        </div>
      </div>
    </template>
    <template #page-content>
      <div
        id="table-request-employment"
        class="table-timeoff-type table-request-personal"
      >
        <b-table
          :data="data ? data.data : []"
          :per-page="perPage"
          :current-page.sync="page"
          :loading="isRefundLoading"
          :sticky-header="stickyHeaders"
          :total="total"
          paginated
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          hoverable
          ref:table
          backend-pagination
          backend-sorting
          @sort="onSort"
          @page-change="onPageChange"
          class="table-voucher-management"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <template>
            <b-table-column field="id" v-slot="props" label="Order ID" sortable>
              <span class="is-capitalize">
                {{ props.row.orderId }}
              </span>
            </b-table-column>
            <b-table-column
              field="pay_date"
              v-slot="props"
              label="Date"
              sortable
            >
              <span class="is-capitalize">
                {{ formatDate(props.row.date) }}
              </span>
            </b-table-column>
            <b-table-column
              field="refund_at"
              v-slot="props"
              label="Refund At"
              sortable
            >
              <span class="is-capitalize">
                {{ formatDate(props.row.refundAt) }}
              </span>
            </b-table-column>
            <b-table-column
              field="refund_amount"
              v-slot="props"
              label="Amount"
              sortable
            >
              <span class="is-capitalize">
                Rp. {{ formatCurrency(props.row.refundAmount) }}
              </span>
            </b-table-column>
            <b-table-column
              field="refund_at"
              v-slot="props"
              label="Received Date"
              sortable
            >
              <span class="is-capitalize">
                {{
                  props.row.receivedDate
                    ? formatDate(props.row.receivedDate)
                    : '-'
                }}
              </span>
            </b-table-column>
            <b-table-column field="status" label="Status" v-slot="props">
              <span
                class="refund-status is-capitalize"
                :class="props.row.status"
              >
                {{ props.row.status }}
              </span>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                No data yet, please contact us if you have any problems
                (hello@getrise.id)
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </template>
  </r-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
// import SubscriptionRefundFilter from '../../components/FilterContent/SubscriptionRefundFilter.vue'
import GeneralFilter from '../../components/FilterContent/GeneralFilter.vue'

import moment from 'moment-timezone'
import indexingParams from '@/views/InternalAdmin/Company/mixins/indexingParams'
import { showToast } from '@/services/util'
export default {
  components: {
    // SubscriptionRefundFilter,
    GeneralFilter,
  },
  mixins: [indexingParams],
  data() {
    return {
      data: [],
      total: 0,
      lastPage: 1,
      page: 0,
      perPage: 10,
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'asc',
      isPaginated: true,
      stickyHeaders: true,
      search: '',
      from: 0,
      isFilterOpen: false,
      isRefundLoading: false,
      statuses: [
        {
          title: 'Waiting',
          value: 'waiting',
          isSelected: false,
        },
        {
          title: 'Rejected',
          value: 'rejected',
          isSelected: false,
        },
        {
          title: 'Approved',
          value: 'approved',
          isSelected: false,
        },
      ],
      statusArray: [],
    }
  },

  async mounted() {
    this.setInvoiceRefund([])
    await this.loadInvoiceRefundData()
  },

  computed: {
    ...mapGetters({
      getInvoiceRefund: 'subscription/getInvoiceRefund',
    }),
  },

  methods: {
    ...mapActions({
      actionLoadRefundData: 'subscription/fetchInvoiceRefund',
    }),
    ...mapMutations({
      setInvoiceRefund: 'subscription/setInvoiceRefund',
    }),

    /**
     *  Handle for open filter
     */
    openFilter() {
      this.isFilterOpen = true
    },

    /**
     *  Handle for close filter
     */
    closeFilter() {
      this.isFilterOpen = false
    },

    /**
     *  HandleClick for change filter
     *  @param {object} event - listener for change filter
     */
    filterBy(event) {
      let index = event.index
      const value =
        event.type === 'status'
          ? this.statusArray.indexOf(`filter_status[]=${event.item.value}`)
          : this.packageArray.indexOf(`filter_package[]=${event.item.value}`)

      if (value > -1) {
        this.addFilter({ value, index, type: event.type })
      } else {
        this.removeFilter({ value: event.item.value, index, type: event.type })
      }
    },

    /**
     *  Add filter to array
     *  @param {object} param
     */
    addFilter(param) {
      const { value, index, type } = param

      if (type === 'status') {
        this.statusArray.splice(value, 1)
        this.statuses[index].isSelected = false
      } else {
        this.packageArray.splice(value, 1)
        this.packageFilters[index].isSelected = false
      }
    },

    /**
     *  Remove filter in array
     *  @param {object} param
     */
    removeFilter(param) {
      const { value, index, type } = param

      if (type === 'status') {
        this.statusArray.push(`filter_status[]=${value}`)
        this.statuses[index].isSelected = true
      } else {
        this.packageArray.push(`filter_package[]=${value}`)
        this.packageFilters[index].isSelected = true
      }
    },

    /**
     *  Function to fetch invoice refund data
     */
    async loadInvoiceRefundData() {
      try {
        let params = [
          ...this.getParams({
            page: this.page,
            perPage: this.perPage,
            sortField: this.sortField,
            sortOrder: this.sortOrder,
            search: this.search,
            additionalFilters: [this.statusArray],
          }),
        ]

        let response = await this.actionLoadRefundData({
          parameter: params.join('&'),
        })
        if (response && response.status === 200) {
          this.data = response.data
        }

        this.lastPage = response.data.meta.lastPage
        this.total = response.data.meta.total
        this.from = response.data.meta.total
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-top')
      }
    },

    /**
     *  Function for format date
     * @param {string} str - date value
     */
    formatDate(str) {
      if (str) {
        return moment(str).format('DD MMMM YYYY')
      }
    },

    /**
     *  Function for format currency into Rupiah
     * @param {number} val - value
     */
    formatCurrency(val) {
      return new Intl.NumberFormat('id-ID', {
        minimumFractionDigits: 0,
      }).format(val)
    },

    /**
     *  Function to sort table
     * @param {string} field - field type
     * @param {string} order - order type
     */
    async onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.setInvoiceRefund([])
      await this.loadInvoiceRefundData()
    },

    /**
     *  Handle for change pagination
     * @param {number} page - page number
     */
    onPageChange(page) {
      this.page = page
      this.loadInvoiceRefundData()
    },

    /**
     *  Handle for clear status filter
     */
    async clearStatus() {
      this.statusArray = []
      this.statuses.forEach((el) => (el.isSelected = false))
      this.setInvoiceRefund([])
      await this.loadInvoiceRefundData()
    },

    /**
     *  Function to reset all filter state
     */
    async resetState() {
      this.statusArray = []
      this.statuses.forEach((el) => (el.isSelected = false))
      this.page = 0
      this.lastPage = 0
      this.setInvoiceRefund([])
      await this.loadInvoiceRefundData()
    },
  },
}
</script>
